import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTimes, faHeart, faHeart as faHeartSolid, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useUser } from './UserContext';
import { useAuth } from './AuthContext';
import { API_URL } from './constants';
import EditSharedWithModal from './EditSharedWithModal'; // New component

import './ItemDetailsModal.css';

function ItemDetailsModal({ item, onClose, onDelete, onStatusUpdate, isOwner, canExpressInterest = true }) {
  const [isInterestExpressed, setIsInterestExpressed] = useState(false);
  const [interestTimestamp, setInterestTimestamp] = useState(null);
  const [isInterestApproved, setIsInterestApproved] = useState(item.status === 'approved');
  const [isItemTransferred, setIsItemTransferred] = useState(item.status === 'transferred');
  const [selectedUser, setSelectedUser] = useState(null);
  const { userId } = useUser();
  const [sharedWith, setSharedWith] = useState([]);
  const [interestedUsers, setInterestedUsers] = useState([]);
  const [error, setError] = useState('');
  const [itemStatus, setItemStatus] = useState(item.status);
  const { previewMode } = useAuth();

  const [showEditSharedModal, setShowEditSharedModal] = useState(false);

  const postedDate = new Date(item.date_posted).toLocaleDateString("en-US", {
    year: 'numeric', month: 'long', day: 'numeric'
  });

  useEffect(() => {
    async function fetchInterestStatusAndUsers() {
      try {
        const interestUrl = `${API_URL}/check-interest?item_id=${item.id}&user_id=${userId}`;
        const interestResponse = await fetch(interestUrl, { credentials: 'include' });
        if (!interestResponse.ok) throw new Error(`HTTP error! Status: ${interestResponse.status}`);
        const interestData = await interestResponse.json();
        setIsInterestExpressed(interestData.isInterested);
        if (interestData.isInterested) {
          setInterestTimestamp(interestData.timestamp);
        }

        setSelectedUser(item.recipient_name);
        setIsInterestApproved(item.status === 'approved');
        setIsItemTransferred(item.status === 'transferred');

        if (isOwner) {
          // Fetch interested users details if owner
          const usersUrl = `${API_URL}/interested-users/${item.id}`;
          const usersResponse = await fetch(usersUrl, { credentials: 'include' });
          if (!usersResponse.ok) throw new Error('Failed to fetch interested users');
          const usersData = await usersResponse.json();
          setInterestedUsers(usersData.length ? usersData : null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchInterestStatusAndUsers();
  }, [item.id, userId, isOwner]);

  useEffect(() => {
    const fetchSharedDetails = async () => {
      try {
        const response = await fetch(`${API_URL}/shared-details/${item.id}`);

        if (!response.ok) throw new Error('Failed to fetch shared details');
        const { groups, individuals } = await response.json();
        const combinedShared = [
          ...Object.entries(groups).map(([id, name]) => ({ id, name, type: 'group' })),
          ...Object.entries(individuals).map(([id, name]) => ({ id, name, type: 'individual' }))
        ];
        combinedShared.sort((a, b) => a.type.localeCompare(b.type));
        setSharedWith(combinedShared.length ? combinedShared : []);
      } catch (error) {
        console.error('Error fetching shared details:', error);
        setError('Error fetching shared details');
      }
    };

    if (item && isOwner) {
      fetchSharedDetails();
    }
  }, [item, isOwner]);

  const handleUserSelection = async (interestedUserId) => {
    try {
      const ownerId = item.owner_id;

      const response = await fetch(`${API_URL}/accept-interest`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          item_id: item.id,
          owner_id: ownerId,
          interested_user_id: interestedUserId
        })
      });

      const data = await response.json();
      if (response.ok) {
        setItemStatus('approved')
        onStatusUpdate(item.id, 'approved');
        alert('Request approved successfully.');
        onClose();
      } else {
        throw new Error(data.message || 'Failed to accept interest');
      }
    } catch (error) {
      console.error('Error accepting interest:', error);
      alert('Error accepting interest');
    }
  };

  const handleConfirmTransfer = async () => {
    try {
      const response = await fetch(`${API_URL}/confirm-transfer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          itemId: item.id
        })
      });
      const data = await response.json();
      if (response.ok) {
        alert('Transfer confirmed successfully.');
        setItemStatus('transferred');
        onStatusUpdate(item.id, 'transferred');
        onClose();
      } else {
        throw new Error(data.message || 'Failed to confirm transfer');
      }
    } catch (error) {
      console.error('Error confirming transfer:', error);
      alert('Error confirming transfer');
    }
  };

  const handleExpressInterest = async (id) => {
    try {
      const response = await fetch(`${API_URL}/express-interest`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ itemId: id, userId: userId }),
        credentials: 'include'
      });
      const data = await response.json();
      if (response.ok) {
        setIsInterestExpressed(true);
        setInterestTimestamp(new Date().toISOString());
        setItemStatus('requested');
      } else {
        alert(data.message || 'Failed to express interest');
      }
    } catch (error) {
      console.error('Failed to express interest:', error);
      alert('Error expressing interest');
    }
  };

  const handleDeleteItem = async (itemId) => {
    try {
      const response = await fetch(`${API_URL}/delete-item/${itemId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      });
      if (response.ok) {
        onDelete(itemId);
        alert('Item deleted successfully');
        onClose();
      } else {
        const errorData = await response.json();
        alert(errorData.message);
      }
    } catch (error) {
      console.error('Failed to delete the item:', error);
      alert('Error deleting the item');
    }
  };

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) onClose();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()} ${date.toLocaleString("en-US", { month: 'short' })}'${date.getFullYear().toString().slice(2)}`;
  };

  const handleUpdateShared = (newGroups, newIndividuals) => {
    // Update the UI after successful update
    const updatedShared = [
      ...newGroups.map(g => ({ id: g.id, name: g.group_name, type: 'group' })),
      ...newIndividuals.map(i => ({ id: i.id, name: i.name, type: 'individual' }))
    ];
    updatedShared.sort((a, b) => a.type.localeCompare(b.type));
    setSharedWith(updatedShared);
  };

  return (
    <div className="modal" onClick={handleBackgroundClick}>
      <div className="modal-content">
        <div className="modal-header">
          <button className="close-btn" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {isOwner && (
            <button className="btn-danger" onClick={() => handleDeleteItem(item.id)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          )}
        </div>

        <div className="item-details">
          {isItemTransferred && (
            <div className="transfer-complete">
              <p>{isOwner ? "Transferred" : "Received"} on {formatDate(item.date_transferred)}.</p>
              {isOwner && <p>Recipient: {selectedUser}</p>}
            </div>
          )}

          {isOwner && isInterestApproved && !isItemTransferred && (
            <div className="transfer-section">
              <button className="btn-confirm-transfer" onClick={handleConfirmTransfer}>
                Tap to Confirm Transfer
              </button>
              <p style={{ all: 'revert' }}>Recipient: {selectedUser}</p>
              <p style={{ all: 'revert' }}>Email: {item.recipient_email}</p>
            </div>
          )}

          {isOwner && itemStatus === 'requested' && (
            <div className="interested-users-section">
              <h4>Tap to Approve Request:</h4>
              <div className="user-buttons-container">
                {interestedUsers && interestedUsers.length > 0 ? (
                  interestedUsers.map(user => (
                    <button
                      key={user.id}
                      className="user-btn"
                      onClick={() => handleUserSelection(user.id)}
                    >
                      {user.name}
                    </button>
                  ))
                ) : (
                  <p>No interested users yet.</p>
                )}
              </div>
            </div>
          )}

          {isOwner && (
            <div className="shared-details">
              <h4>Shared With:
                <button
                  className="edit-btn"
                  onClick={() => setShowEditSharedModal(true)}
                  style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </h4>
              {sharedWith && sharedWith.length > 0 ? (
                <ul>
                  {sharedWith.map((s, index) => (
                    <li key={index}>{s.name} ({s.type})</li>
                  ))}
                </ul>
              ) : (
                <p>No groups or individuals selected.</p>
              )}
            </div>
          )}

          {!isOwner && (
            <>
              {!isOwner && canExpressInterest && !isInterestExpressed &&
                item.status !== 'approved' && item.status !== 'transferred' && (
                  <button className="btn-interest" onClick={() => handleExpressInterest(item.id)}
                    style={previewMode ? { cursor: 'not-allowed', opacity: 0.6 } : {}}
                  >
                    <FontAwesomeIcon icon={faHeart} /> Express Interest
                  </button>
                )}
              {isInterestExpressed && itemStatus === 'requested' && (
                <button className="btn-interest-expressed" disabled>
                  <FontAwesomeIcon icon={faHeartSolid} /> Interest Expressed on {formatDate(interestTimestamp)}. Pending approval...
                </button>
              )}
              {isInterestExpressed && item.status === 'approved' && (
                <button className="btn-interest-approved" disabled>
                  <FontAwesomeIcon icon={faHeartSolid} /> Interest Approved on {formatDate(item.date_transferred)}. Contact owner at {item.owner_email} to arrange pick up ...
                </button>
              )}

            </>
          )}

          <img src={`/images/${item.thumbnail_url}`} alt={item.name} className="image-preview" />
          <p className="image-tip">Press and hold the image to see it fully.</p>
          <div className="item-info">
            <p className="item-name">Item {item.id}: {item.name}</p>
            <p className="item-description">{item.description}</p>
            <p className="item-owner">
              Owner:
              <span className={previewMode ? "blur-content" : ""}>
                {item.owner_name}
              </span>
            </p>
            <p className="item-date">
              Posted on:
              <span className={previewMode ? "blur-content" : ""}>
                {postedDate}
              </span>
            </p>
          </div>
        </div>
      </div>

      {showEditSharedModal && item.status !== 'approved'(
        <EditSharedWithModal
          itemId={item.id}
          userId={userId}
          currentShared={sharedWith}
          onClose={() => setShowEditSharedModal(false)}
          onUpdate={handleUpdateShared}
        />
      )}
    </div>
  );
}

export default ItemDetailsModal;
