// RecordList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLink,
  faPlusCircle,
  faHourglassHalf,
  faBell
} from '@fortawesome/free-solid-svg-icons';

import ItemCard from './ItemCard';
import WishListRow from './WishListRow';
import ItemDetailsModal from './ItemDetailsModal';
import WishDetailsModal from './WishDetailsModal';
import { useAuth } from './AuthContext';
import { useUser } from './UserContext';
import useDebounce from './useDebounce';
import { CATEGORIES } from './constants';

import 'bootstrap/dist/css/bootstrap.min.css';
import AddItemModal from './AddItemModal';
import AddWishModal from './AddWishModal';
import ShareLibraryModal from './ShareLibraryModal'; 
import ShareLinkModal from './ShareLinkModal';        // NEW fallback link modal
import './RecordList.css';

function RecordList({ type }) {
  const { userId } = useUser();
  const { previewMode } = useAuth();

  const [showPreviewNotification, setShowPreviewNotification] = useState(previewMode);
  const [isLoading, setIsLoading] = useState(true);

  // Filter / search
  const [category, setCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [showMyLibrary, setShowMyLibrary] = useState(false);
  const [giveawayOption, setGiveawayOption] = useState(false);

  // Items
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const isWishlist = (type === 'wishes');

  // Stats (optional)
  const [stats, setStats] = useState({
    totalAvailable: 0,
    totalTransacted: 0,
    totalCo2Saved: 0
  });

  // Modals
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showAddWishModal, setShowAddWishModal] = useState(false);

  // CREATE LIBRARY MODE
  const [createLibraryMode, setCreateLibraryMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // For short success/error messages
  const [message, setMessage] = useState('');

  // For collecting desc/password
  const [showShareModal, setShowShareModal] = useState(false);

  // For fallback link display if clipboard fails
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [fallbackLink, setFallbackLink] = useState('');

  useEffect(() => {
    fetchEntities();
    fetchStatistics();
  }, [userId, category, debouncedSearchQuery, showMyLibrary, giveawayOption]);

  const fetchEntities = () => {
    const url = isWishlist ? '/api/wishlist' : '/api/items';
    const params = {
      userId,
      giveaway: giveawayOption,
      showMyLibrary,
      category,
      searchQuery: debouncedSearchQuery,
    };

    setIsLoading(true);
    axios
      .get(url, { params })
      .then((response) => {
        setItems(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(`Error fetching ${isWishlist ? 'wishlist' : 'items'}:`, error);
        setIsLoading(false);
      });
  };

  const fetchStatistics = () => {
    axios
      .get('/api/item-stats', { params: { userId, giveaway: giveawayOption } })
      .then((response) => {
        setStats({
          totalAvailable: response.data.total_available,
          totalTransacted: response.data.total_transacted,
          totalCo2Saved: null
        });
      })
      .catch((error) => console.error('Error fetching item statistics:', error));
  };

  // Normal item detail
  const handleItemSelect = (item) => {
    setSelectedItem(item);
  };
  const handleItemDeleted = (deletedId) => {
    setItems((curr) => curr.filter((itm) => itm.id !== deletedId));
    setSelectedItem(null);
  };

  // Toggling "Mine"
  const handleToggleMine = () => {
    setShowMyLibrary(!showMyLibrary);
    // exit share mode & reset selection
    setCreateLibraryMode(false);
    setSelectedItems([]);
    setMessage('');
  };

  // Toggling share mode
  const toggleCreateLibraryMode = () => {
    if (!createLibraryMode) {
      setSelectedItems([]);
      setMessage('');
    }
    setCreateLibraryMode(!createLibraryMode);
  };

  // Toggling item selection if in share mode
  const toggleItemSelection = (itemId) => {
    setSelectedItems((prev) =>
      prev.includes(itemId) 
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId]
    );
  };

  // Submitting => open the "ShareLibraryModal"
  const handleSubmitLibraryClick = () => {
    if (selectedItems.length === 0) {
      setMessage('Select at least one item to share.');
      return;
    }
    setShowShareModal(true);
  };

  // If user confirms desc & password in the ShareLibraryModal
  const handleConfirmShare = async ({ description, password }) => {
    // close the share modal
    setShowShareModal(false);

    try {
      // No expiry => forever
      const payload = {
        userId,
        itemIds: selectedItems,
        description: description.trim() || '',
        password: password.trim() || '',
        expiresAt: null,
        giveaway: giveawayOption
      };

      const response = await axios.post('/api/shared-library', payload);
      const link = response.data?.shareableLink || '';

      if (!link) {
        setMessage('No link returned from server. Please try again.');
        return;
      }

      // Attempt to copy
      try {
        await navigator.clipboard.writeText(link);
        setMessage('Link copied to clipboard!');
      } catch (copyError) {
        console.warn('Clipboard copy failed:', copyError);
        // Instead of alert, show our fallback link modal
        setFallbackLink(link);
        setShowLinkModal(true);
        setMessage('');
      }

      // Exit share mode after success
      setCreateLibraryMode(false);
      setSelectedItems([]);
    } catch (error) {
      console.error('Error creating shared library:', error);
      setMessage('Failed to create library. Please try again.');
    }
  };

  // Cancel share => user closes the ShareLibraryModal => exit share mode
  const handleCancelShare = () => {
    setShowShareModal(false);
    setCreateLibraryMode(false);
    setSelectedItems([]);
  };

  // Once user closes the fallback link modal, we do not re-enter share mode
  const handleCloseLinkModal = () => {
    setShowLinkModal(false);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="container mt-4 position-relative">
      {showPreviewNotification && (
        <div className="preview-notification">
          <span>
            <FontAwesomeIcon icon={faBell} />{' '}
            You are currently in <strong>preview mode</strong>. Some functionalities are limited.{' '}
            <a href="/login" className="login-link">Login here</a>
          </span>
          <button
            className="close-notification"
            onClick={() => setShowPreviewNotification(false)}
            aria-label="Close notification"
          >
            ✕
          </button>
        </div>
      )}

      {/* Filter & toggles */}
      <div className="row mb-3">
        <div className="col-md-4">
          <select
            className="form-select form-select-lg custom-select-size fs-6"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {CATEGORIES.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Search items..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="controls-toolbar">
          {!isWishlist && (
            <div className="segmented-control" role="group" aria-label="Item Options">
              <button
                className={`segment-button ${!giveawayOption ? 'active' : ''}`}
                onClick={() => setGiveawayOption(false)}
              >
                Borrow
              </button>
              <button
                className={`segment-button ${giveawayOption ? 'active' : ''}`}
                onClick={() => setGiveawayOption(true)}
              >
                Rehome
              </button>
            </div>
          )}

          <div className="user-scope-toggle">
            <label htmlFor="toggle-switch-listings">
              {showMyLibrary ? 'Mine' : 'Others'}
            </label>
            <label className="switch">
              <input
                type="checkbox"
                id="toggle-switch-listings"
                checked={showMyLibrary}
                onChange={handleToggleMine}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      {/* "Share" button toggles library mode */}
      {showMyLibrary && !isWishlist && (
        <div style={{ margin: '10px 0' }}>
          <button
            className={`btn ${createLibraryMode ? 'btn-danger' : 'btn-success'} me-2`}
            onClick={toggleCreateLibraryMode}
            style={{ padding: '6px 12px', fontSize: '0.9rem' }}
          >
            {createLibraryMode ? 'Exit' : 'Create Shareable Link'}
          </button>
        </div>
      )}

      {/* Show short messages */}
      {message && (
        <div style={{ marginBottom: '1rem', color: 'green' }}>
          {message}
        </div>
      )}

      {/* If in share mode, guide user */}
      {createLibraryMode && (
        <div className="preview-notification" style={{ marginBottom: '1rem' }}>
          <p>
            Tap items to select them for sharing. Then press <strong>Submit</strong> when ready.
          </p>
        </div>
      )}

      {/* If in share mode & the share modal is NOT open => floating "Submit(#)" */}
      {createLibraryMode && !showShareModal && (
        <button
          onClick={handleSubmitLibraryClick}
          className="btn btn-primary"
          style={{
            position: 'fixed',
            bottom: '80px',
            right: '20px',
            zIndex: 9999,
            padding: '10px 16px',
            fontSize: '0.9rem'
          }}
        >
          Submit ({selectedItems.length})
        </button>
      )}

      {/* If no items found */}
      {!isLoading && items.length === 0 && (
        <div className="preview-notification">
          <p>No items found. Try subscribing or adding items.</p>
        </div>
      )}

      {/* Main items layout */}
      <div className="row mt-3">
        <div className="d-flex flex-wrap justify-content-around">
          {items.map((item) => {
            if (isWishlist) {
              return (
                <WishListRow
                  key={item.id}
                  wishlist={item}
                  onItemSelect={() => setSelectedItem(item)}
                />
              );
            }

            const isSelected = selectedItems.includes(item.id);
            const handleClick = () => {
              if (createLibraryMode) {
                toggleItemSelection(item.id);
              } else {
                setSelectedItem(item);
              }
            };

            return (
              <ItemCard
                key={item.id}
                item={item}
                isSelected={isSelected}
                onItemSelect={handleClick}
              />
            );
          })}
        </div>
      </div>

      {/* Item detail modal if user clicked item in normal mode */}
      {selectedItem && (
        isWishlist ? (
          <WishDetailsModal
            item={selectedItem}
            onClose={() => setSelectedItem(null)}
            onDelete={handleItemDeleted}
            isOwner={userId === selectedItem.owner_id}
          />
        ) : (
          <ItemDetailsModal
            item={selectedItem}
            onClose={() => setSelectedItem(null)}
            onDelete={handleItemDeleted}
            isOwner={userId === selectedItem.owner_id}
          />
        )
      )}

      {/* AddItem / AddWish modals if used */}
      {showAddItemModal && !selectedItem && !showAddWishModal && (
        <AddItemModal
          show={showAddItemModal}
          onClose={() => setShowAddItemModal(false)}
          giveaway_flag={giveawayOption}
        />
      )}
      {showAddWishModal && !selectedItem && !showAddItemModal && (
        <AddWishModal
          show={showAddWishModal}
          onClose={() => setShowAddWishModal(false)}
          giveaway_flag={giveawayOption}
          type="wish"
        />
      )}

      {/* 
        The share library modal => user enters desc/password
        If user cancels => we fully exit share mode
      */}
      <ShareLibraryModal
        show={showShareModal}
        onClose={handleCancelShare}
        onConfirm={handleConfirmShare}
      />

      {/*
        The fallback link modal => if clipboard fails,
        we show a neat interface with the link
      */}
      <ShareLinkModal
        show={showLinkModal}
        link={fallbackLink}
        onClose={handleCloseLinkModal}
      />
    </div>
  );
}

export default RecordList;
