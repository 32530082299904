// PublicHeader.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import LoopIcon from '@mui/icons-material/Loop';

// The same icon pairing from your code
const LoginIcon = () => (
  <div className="login-icon" style={{ position: 'relative' }}>
    <PeopleIcon style={{ fontSize: 24, color: '#4CAF50' }} />
    <LoopIcon
      style={{
        position: 'absolute',
        top: '50%',
        left: '100%',
        transform: 'translate(-50%, -50%)',
        fontSize: 12,
        color: '#4CAF50',
        opacity: 0.6
      }}
    />
  </div>
);

function PublicHeader() {
  // No toggleMenu or buttonRef needed. We can do a no-op hamburger or omit it entirely
  return (
    <header className="header">

      <div className="logo-container">
        {/* If you want this link to go somewhere public, adjust accordingly */}          <div className="branding">
            <span>
               Together, We Have Enough <LoginIcon />
            </span>
          </div>
      </div>
    </header>
  );
}

export default PublicHeader;
