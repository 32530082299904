// src/pages/FAQ.js

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './FAQ.css';

const FAQ = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const faqs = [
    // Getting Started Section
    {
      title: "Getting Started",
      questions: [
        {
          question: "How do I join communities?",
          answer: "Tap the <strong>Network</strong> icon at the bottom right and join groups that align with your interests. This helps ensure your items are shared with the right people!"
        },
        {
          question: "How can I connect with trusted members?",
          answer: "Go to <strong>Network</strong> and select <strong>Connections</strong> to find individuals you want to connect with. Sending connection requests to trusted members allows you to share items exclusively, like lending a cake pan to a baking enthusiast or sharing books with a fellow reader."
        }
      ]
    },

    // Sharing Items Section
    {
      title: "Sharing Items",
      questions: [
        {
          question: "How do I contribute items to the community?",
          answer: "Click the <strong>+</strong> (post) icon at the bottom of the app to add items you’re ready to share or give away. Follow the prompts to complete your post."
        },
        {
          question: "How do I express interest in an item?",
          answer: "Interested in an item? Click <strong>Express Interest</strong> on its page. Once the owner approves, you’ll receive their email to coordinate directly. CommunityCycle thrives when members connect in person—whether it's parents at schools or neighbors in your area."
        },
        {
          question: "How can I track the status of my expressed interest?",
          answer: "After expressing interest, tap the <strong>Home</strong> icon <strong>⌂</strong> at the bottom left and select <strong>My Requests</strong> to view the status of your requests."
        },
        {
          question: "How do I update the status after transferring an item?",
          answer: "Once you've handed over an item, go to the <strong>Home</strong> icon <strong>⌂</strong>, then navigate to <strong>My Shared Items</strong> > <strong>Pending Transfer</strong>, and click <strong>Confirm Transfer</strong> to finalize the process."
        }
      ]
    },

    // Requesting Items Section
    {
      title: "Requesting Items",
      questions: [
        { 
          question: "What if I can't find the item I'm looking for?",
          answer: "Post your request by clicking the <strong>+</strong> (post) icon at the bottom of the app and follow the prompts. This lets the community know what you're seeking."
        },
        { 
          question: "How do I track the status of my expressed interest?",
          answer: "After expressing interest, tap the <strong>Home</strong> icon <strong>⌂</strong> at the bottom left and select <strong>My Requests</strong> to view the status of your requests." 
        }
      ]
    },

    // Wishlist Section
    {
      title: "Wishlist",
      questions: [
        { 
          question: "What is a wishlist and how does it work?",
          answer: "Wishlists let you post items you wish to borrow or receive. They communicate your needs to the community, enabling others to offer items that match your requests." 
        },
        { 
          question: "How can I check the status of my wishlist?",
          answer: "When matching items are found, they appear under <strong>Matched Wishes</strong>. Access this by tapping the <strong>Home</strong> icon and selecting <strong>My Wishlist</strong>." 
        }
      ]
    },

    // Support and Feedback Section
    {
      title: "Support and Feedback",
      questions: [
        { 
          question: "Who do I contact for questions or suggestions?",
          answer: "Open the menu (☰) at the top left, select <strong>Feedback</strong>, and post your questions or suggestions there." 
        }
      ]
    },

    // Community Impact Section
    {
      title: "Community Impact",
      questions: [
        {
          question: "How does CommunityCycle benefit the community?",
          answer: "By participating, you help reduce waste, conserve resources, and strengthen community bonds. We're excited to build a sustainable future together—thank you for being a part of this journey!"
        }
      ]
    }
  ];

  const toggleSection = (index) => {
    setActiveSection(activeSection === index ? null : index);
    setActiveQuestion(null); // Close any open question when a new section is opened
  };

  const toggleQuestion = (questionIndex) => {
    setActiveQuestion(activeQuestion === questionIndex ? null : questionIndex);
  };

  return (
    <div className="faq-page">
      {faqs.map((section, sectionIndex) => (
        <div key={sectionIndex} className="faq-section">
          <div 
            className={`section-header ${activeSection === sectionIndex ? 'active' : ''}`}
            onClick={() => toggleSection(sectionIndex)}
            aria-expanded={activeSection === sectionIndex}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                toggleSection(sectionIndex);
              }
            }}
          >
            <h2 className="section-title">{section.title}</h2>
            <FontAwesomeIcon 
              icon={activeSection === sectionIndex ? faChevronUp : faChevronDown} 
              className="toggle-icon" 
            />
          </div>
          {activeSection === sectionIndex && (
            <div className="section-content">
              {section.questions.map((item, questionIndex) => (
                <div key={questionIndex} className="faq-question">
                  <div 
                    className={`question-header ${activeQuestion === questionIndex ? 'active' : ''}`}
                    onClick={() => toggleQuestion(questionIndex)}
                    aria-expanded={activeQuestion === questionIndex}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        toggleQuestion(questionIndex);
                      }
                    }}
                  >
                    <h3 className="question-title">{item.question}</h3>
                    <FontAwesomeIcon 
                      icon={activeQuestion === questionIndex ? faChevronUp : faChevronDown} 
                      className="toggle-icon" 
                    />
                  </div>
                  {activeQuestion === questionIndex && (
                    <div 
                      className="faq-answer" 
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                      aria-live="polite"
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
