import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Feedback.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faPaperPlane, faCommentDots, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useUser } from './UserContext';

function Feedback() {
  const [feedbackList, setFeedbackList] = useState([]);
  const [anonymous, setAnonymous] = useState(false);
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState('upvotes'); // Default sort by upvotes
  const { userId } = useUser();
  const [showForm, setShowForm] = useState(false); // Toggle feedback form visibility
  const [responseMessage, setResponseMessage] = useState(null); // Success/Error messages

  const fetchFeedback = async () => {
    setLoading(true);
    try {
      const res = await axios.get('/api/feedback', {
        params: { sort: sortOption }
      });
      setFeedbackList(res.data);
    } catch (error) {
      console.error('Error fetching feedback:', error);
      setResponseMessage({ type: 'error', text: 'Failed to load feedback. Please try again later.' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim()) {
      setResponseMessage({ type: 'error', text: 'Feedback cannot be empty.' });
      return;
    }

    try {
      const res = await axios.post('/api/feedback', { userId, content, anonymous });
      setFeedbackList((prev) => [res.data, ...prev]);
      setContent('');
      setAnonymous(false);
      setResponseMessage({ type: 'success', text: 'Thank you for your feedback!' });
      setShowForm(false); // Hide form after successful submission
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setResponseMessage({ type: 'error', text: 'Failed to submit feedback. Please try again.' });
    }
  };

  const handleUpvote = async (id) => {
    try {
      const res = await axios.post('/api/feedback/upvote', { id, userId });
      if (res.data.message) {
        setResponseMessage({ type: 'info', text: res.data.message });
        return;
      }

      setFeedbackList((prev) =>
        prev.map((item) => (item.id === id ? res.data : item))
      );

      setFeedbackList((prev) =>
        [...prev].sort((a, b) => {
          if (sortOption === 'upvotes') {
            return b.upvotes - a.upvotes || new Date(b.created_at) - new Date(a.created_at);
          } else {
            return new Date(b.created_at) - new Date(a.created_at);
          }
        })
      );
    } catch (error) {
      console.error('Error upvoting feedback:', error);
      setResponseMessage({ type: 'error', text: 'Failed to upvote. Please try again.' });
    }
  };

  return (
    <div className="feedback-container">
      <div className="intro-section">
        <h5 className="intro-text">
          We value your suggestions to help us improve!
        </h5>
        <button className="toggle-form-button" onClick={() => setShowForm(!showForm)}>
          <FontAwesomeIcon icon={showForm ? faTimes : faCommentDots} />
          {showForm ? ' Cancel' : ' Leave Feedback'}
        </button>
      </div>

      {showForm && (
        <form className="feedback-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <textarea
              placeholder="Write your suggestion here..."
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows={3}
              required
            />
          </div>

          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              id="anonymous"
              checked={anonymous}
              onChange={() => setAnonymous(!anonymous)}
            />
            <label htmlFor="anonymous">Post Anonymously</label>
          </div>

          <button type="submit" className="submit-button" disabled={loading}>
            <FontAwesomeIcon icon={faPaperPlane} /> Submit
          </button>
        </form>
      )}

      {responseMessage && (
        <div className={`response-message ${responseMessage.type}`}>
          {responseMessage.text}
        </div>
      )}

      <div className="sort-buttons">
        <button
          className={`sort-button ${sortOption === 'upvotes' ? 'active' : ''}`}
          onClick={() => setSortOption('upvotes')}
        >
          Most Upvoted
        </button>
        <button
          className={`sort-button ${sortOption === 'recent' ? 'active' : ''}`}
          onClick={() => setSortOption('recent')}
        >
          Most Recent
        </button>
      </div>

      <h3 className="list-heading">All Feedback</h3>

      {loading ? (
        <div className="loading-state">Loading feedback...</div>
      ) : (
        <div className="feedback-list">
          {feedbackList.length === 0 ? (
            <p className="no-feedback">No suggestions yet. Be the first to contribute!</p>
          ) : (
            feedbackList.map((item) => (
              <div key={item.id} className="feedback-item">
                <div className="feedback-content">
                  <p className="feedback-text">{item.content}</p>
                  <p className="feedback-meta">
                    Posted by {item.anonymous ? 'Anonymous' : (item.name || 'Anonymous')}
                  </p>
                </div>
                <div className="feedback-actions">
                  <button
                    className={`upvote-button ${item.userUpvoted ? 'upvoted' : ''}`}
                    onClick={() => handleUpvote(item.id)}
                    disabled={item.userUpvoted}
                    aria-label={`Upvote feedback from ${item.anonymous ? 'Anonymous' : item.name}`}
                  >
                    <FontAwesomeIcon icon={faThumbsUp} /> {item.upvotes}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default Feedback;
