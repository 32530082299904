// src/pages/About.js

import React from 'react';
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faListAlt } from '@fortawesome/free-solid-svg-icons';

const aboutSections = [
  {
    icon: faHandsHelping,
    title: 'Borrow & Share',
    content: 'Borrow items you need from your community and share the ones you don’t use often. Together, we reduce waste and give everyday items a longer life.'
  },
  {
    icon: faListAlt,
    title: 'Wish List',
    content: 'Looking for something specific? Add it to your Wish List and let others in the community help fulfill your needs while promoting reuse and sustainability.'
  }
];

const About = () => {
  return (
    <div className="about-page">
      {/* Page Heading */}
      <div className="about-heading-card">
        <h1 className="about-page-heading">Welcome to CommunityCycle!</h1>
        <p className="about-intro-text">
          A platform where we come together to share and borrow items within our trusted community. Why buy something you’ll only use a few times when you can borrow it from a neighbor? By sharing what we already have, we reduce waste, minimize the demand for new production, and take meaningful steps to address Scope 3 emissions—the hidden environmental costs of manufacturing, transport, and disposal. Let’s build a more sustainable future while strengthening our community—one shared item at a time.
        </p>
      </div>

      {/* About Sections */}
      <div className="about-sections">
        {aboutSections.map((section, index) => (
          <div key={index} className="about-section-card">
            <FontAwesomeIcon icon={section.icon} className="section-icon" />
            <h2 className="section-title">{section.title}</h2>
            <p className="section-content">{section.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
