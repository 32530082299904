// Settings.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faExchangeAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useUser } from './UserContext';
import Switch from 'react-switch';
import { useSpring, animated } from 'react-spring';

import './Settings.css'; // Ensure consistent styling

import { useNotifications } from './NotificationContext'; // Import the Notification Context

function Settings() {
  const { userId } = useUser();
  const [groups, setGroups] = useState([]);
  const [connections, setConnections] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(null); // Initialize to null (no tab selected)
  const [searchIdentifier, setSearchIdentifier] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const { notifications, fetchNotifications } = useNotifications(); // Utilize Notification Context

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // 1) Fetch raw group data (no immediate sorting)
        const groupsRes = await axios.get(`/api/groups/${userId}`);
        setGroups(groupsRes.data);

        // 2) Fetch connections, sorted by is_connected
        const connectionsRes = await axios.get('/api/connections/user', { params: { userId } });
        setConnections(connectionsRes.data.sort((a, b) => b.is_connected - a.is_connected));

        // 3) Fetch pending requests
        const requestsRes = await axios.get('/api/connections/requests', { params: { userId } });
        setPendingRequests(requestsRes.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  // Manage subscription to a group
  const handleGroupAction = async (groupId, action) => {
    try {
      await axios.post('/api/groups/manage', { userId, groupId, action });
      // Update local state only, no sorting
      setGroups((prev) =>
        prev.map((g) =>
          g.id === groupId ? { ...g, is_subscribed: action === 'subscribe' } : g
        )
      );
    } catch (error) {
      console.error('Failed to manage group:', error);
    }
  };

  // Manage connection add/remove
  const handleConnectionAction = async (connectUserId, action) => {
    try {
      await axios.post('/api/connections/manage', { userId, connectUserId, action });
      setConnections((prevConnections) =>
        prevConnections
          .map((conn) =>
            conn.id === connectUserId ? { ...conn, is_connected: action === 'add' } : conn
          )
          .sort((a, b) => b.is_connected - a.is_connected)
      );
      // Refresh notifications after connection action
      await fetchNotifications();
    } catch (error) {
      console.error('Failed to manage connection:', error);
    }
  };

  // Send a connection request
  const handleSendRequest = async () => {
    if (!searchIdentifier.trim()) {
      setResponseMessage('Please enter a full name or email.');
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post('/api/connections/request', {
        identifier: searchIdentifier.trim(),
        userId
      });
      setResponseMessage(res.data.message);
      setSearchIdentifier('');
      // Refresh notifications after sending request
      // await fetchNotifications();
    } catch (error) {
      console.error('Failed to send connection request:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setResponseMessage(error.response.data.message);
      } else {
        setResponseMessage('Failed to send the request. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };


  // Accept or decline a connection request
  const handleRequestResponse = async (requestId, action) => {
    try {
      const acceptedRequest = pendingRequests.find((r) => r.id === requestId);

      const res = await axios.post('/api/connections/respond', {
        requestId,
        action,
        userId
      });

      setResponseMessage(res.data.message);
      setPendingRequests((prev) => prev.filter((req) => req.id !== requestId));

      // If accepted, add to connections
      if (action === 'accept' && acceptedRequest) {
        const connectUserId =
          acceptedRequest.sender_id === userId ? acceptedRequest.receiver_id : acceptedRequest.sender_id;

        setConnections((prev) => {
          const exists = prev.some((conn) => conn.id === connectUserId);
          if (!exists) {
            return [...prev, { id: connectUserId, name: acceptedRequest.sender_name, is_connected: false }];
          }
          return prev;
        });

        await handleConnectionAction(connectUserId, 'add');
      }

      // Refresh notifications after responding to request
      await fetchNotifications();
    } catch (error) {
      console.error('Failed to respond to request:', error);
      setResponseMessage('Failed to process the request. Please try again.');
    }
  };

  // Helper function to sanitize and extract category from group_name
  const sanitizeCategory = (groupName) => {
    if (!groupName || typeof groupName !== 'string') {
      return 'unknown';
    }

    const lowerGroupName = groupName.toLowerCase();

    if (lowerGroupName.includes('nursery')) {
      return 'nursery';
    }
    if (lowerGroupName.includes('kindergarten')) {
      return 'kindergarten';
    }
    if (lowerGroupName.includes('community')) {
      return 'community';
    }
    if (lowerGroupName.includes('grade')) {
      return 'grade';
    }

    return 'unknown';
  };

  // Helper function to sort groups alphabetically by group_name
  const sortAlphabetically = (a, b) => {
    if (!a.group_name || !b.group_name) {
      return 0;
    }
    const comparison = a.group_name.localeCompare(b.group_name);
    return comparison;
  };

  // Helper function to sort by category order
  const sortByCategory = (categoryOrder) => (a, b) => {
    const aCat = sanitizeCategory(a.group_name);
    const bCat = sanitizeCategory(b.group_name);

    const aOrder = categoryOrder[aCat] || 999;
    const bOrder = categoryOrder[bCat] || 999;

    if (aOrder !== bOrder) {
      const orderDifference = aOrder - bOrder;
      return orderDifference;
    }

    return sortAlphabetically(a, b);
  };

  const finalSortGroups = (all) => {
    // Define EXACT keys that match your data after sanitize: "nursery", "kindergarten", "grade"
    const categoryOrder = {
      community: 1,
      nursery: 2,
      kindergarten: 3,
      grade: 4
    };

    // Separate subscribed and unsubscribed groups
    const subscribedGroups = all.filter(group => group.is_subscribed);
    const unsubscribedGroups = all.filter(group => !group.is_subscribed);

    // Sort subscribed groups alphabetically by group_name
    subscribedGroups.sort(sortAlphabetically);

    // Sort unsubscribed groups by category order and then alphabetically by group_name
    unsubscribedGroups.sort(sortByCategory(categoryOrder));

    // Combine the sorted arrays: subscribed groups first, then unsubscribed groups
    const finalSortedGroups = [...subscribedGroups, ...unsubscribedGroups];

    return finalSortedGroups;
  };

  // Animate the tab content
  const slideInStyles = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    config: { tension: 200, friction: 20 }
  });

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  // Tally counts
  const subscribedGroupsCount = groups.filter((g) => g.is_subscribed).length;
  const connectedIndividualsCount = connections.filter((c) => c.is_connected).length;

  // Toggle tab selection
  const toggleTab = (tabName) => {
    setActiveTab((prevTab) => (prevTab === tabName ? null : tabName));
  };

  // Provide the final sorted array
  const sortedGroups = finalSortGroups(groups);

  return (
    <div className="settings-container">
      {/* Tabs */}
      <div className="tabs">
        {(activeTab === null || activeTab === 'groups') && (
          <div className="tab-row">
            <button
              className={`tab-button ${activeTab === 'groups' ? 'active' : ''}`}
              onClick={() => toggleTab('groups')}
              aria-pressed={activeTab === 'groups'}
              aria-label="Groups Tab"
              style={{ position: 'relative' }} // Ensure relative positioning
            >
              <FontAwesomeIcon icon={faUserFriends} /> Groups ({subscribedGroupsCount} Subscribed)
            </button>
          </div>
        )}
        {(activeTab === null || activeTab === 'connections') && (
          <div className="tab-row">
            <button
              className={`tab-button ${activeTab === 'connections' ? 'active' : ''}`}
              onClick={() => toggleTab('connections')}
              aria-pressed={activeTab === 'connections'}
              aria-label="Connections Tab"
              style={{ position: 'relative' }} // Ensure relative positioning
            >
              <FontAwesomeIcon icon={faExchangeAlt} /> Connections ({connectedIndividualsCount} Connected)
            </button>
          </div>
        )}
        {(activeTab === null || activeTab === 'findConnect') && (
          <div className="tab-row">
            <button
              className={`tab-button ${activeTab === 'findConnect' ? 'active' : ''}`}
              onClick={() => toggleTab('findConnect')}
              aria-pressed={activeTab === 'findConnect'}
              aria-label="Find and Connect Tab"
              style={{ position: 'relative' }} // Ensure relative positioning
            >
              <FontAwesomeIcon icon={faSearch} /> Find and Connect
              {notifications.pending_requests > 0 && (
                <span className="notification-badge">{notifications.pending_requests}</span>
              )}
            </button>
          </div>
        )}
      </div>

      {/* Tab Content */}
      <animated.div style={slideInStyles} className="tab-content">
        {activeTab === 'groups' && (
          <GroupsSection
            groups={sortedGroups}
            handleGroupAction={handleGroupAction}
          />
        )}
        {activeTab === 'connections' && (
          <ConnectionsSection
            connections={connections}
            handleConnectionAction={handleConnectionAction}
          />
        )}
        {activeTab === 'findConnect' && (
          <FindAndConnectSection
            searchIdentifier={searchIdentifier}
            setSearchIdentifier={setSearchIdentifier}
            handleSendRequest={handleSendRequest}
            responseMessage={responseMessage}
            pendingRequests={pendingRequests}
            handleRequestResponse={handleRequestResponse}
            loading={loading}
            pendingRequestsCount={notifications.pending_requests} // Pass pending requests count
          />
        )}
        {activeTab === null && (
          <div className="welcome-message">
            <p>Select a tab to view its content.</p>
          </div>
        )}
      </animated.div>
    </div>
  );
}

// Groups Section
function GroupsSection({ groups, handleGroupAction }) {
  return (
    <div className="section-container">
      {groups.map((group) => (
        <div key={group.id} className="card group-card">
          <h6>{group.group_name}</h6>
          {/* No description, crisp design */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleGroupAction(group.id, group.is_subscribed ? 'unsubscribe' : 'subscribe');
            }}
            className={group.is_subscribed ? 'unsubscribe-button' : 'subscribe-button'}
            aria-label={
              group.is_subscribed
                ? 'Unsubscribe from group'
                : 'Subscribe to group'
            }
          >
            {group.is_subscribed ? 'Unsubscribe' : 'Subscribe'}
          </button>
        </div>
      ))}
    </div>
  );
}

// Connections Section
function ConnectionsSection({ connections, handleConnectionAction }) {
  return (
    <div className="section-container">
      {connections.map((conn) => (
        <div
          key={conn.id}
          className={`card connection-card ${conn.is_connected ? 'connected' : 'disconnected'}`}
        >
          <h6>{conn.name}</h6>
          <Switch
            onChange={() => handleConnectionAction(conn.id, conn.is_connected ? 'remove' : 'add')}
            checked={conn.is_connected}
            onColor="#4CAF50"
            offColor="#ccc"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={40}
            aria-label={
              conn.is_connected
                ? `Disconnect from ${conn.name}`
                : `Connect with ${conn.name}`
            }
          />
        </div>
      ))}
    </div>
  );
}

// Find & Connect Section
function FindAndConnectSection({
  searchIdentifier,
  setSearchIdentifier,
  handleSendRequest,
  responseMessage,
  pendingRequests,
  handleRequestResponse,
  loading,
  pendingRequestsCount
}) {
  return (
    <div className="section-container">


      {/* Pending Requests */}
      {pendingRequests.length > 0 && (
        <>
          <h5 className="pending-requests-heading">
            {pendingRequests.length} Pending{' '}
            {pendingRequests.length === 1 ? 'Request' : 'Requests'}
          </h5>
          <div className="pending-requests">
            {pendingRequests.map((request) => (
              <div key={request.id} className="request-card">
                <p>
                  {request.sender_name}
                  {request.sender_email && ` (${request.sender_email})`}
                </p>
                <div className="request-actions">
                  <button
                    onClick={() => handleRequestResponse(request.id, 'accept')}
                    className="accept-button"
                    aria-label={`Accept connection request from ${request.sender_name}`}
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => handleRequestResponse(request.id, 'decline')}
                    className="decline-button"
                    aria-label={`Decline connection request from ${request.sender_name}`}
                  >
                    Decline
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="search-container">
        <input
          type="text"
          placeholder="Enter full name or email"
          value={searchIdentifier}
          onChange={(e) => setSearchIdentifier(e.target.value)}
          className="search-input"
          aria-label="Search by full name or email"
        />
        <button
          onClick={handleSendRequest}
          disabled={loading}
          className="search-button"
          aria-label="Send connection request"
        >
          {loading ? 'Sending...' : 'Send Request'}
        </button>
      </div>
      {responseMessage && <p className="response-message">{responseMessage}</p>}

    </div>
  );
}

export default Settings;
