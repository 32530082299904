// ShareLibraryModal.js
import React, { useState } from 'react';
import './RecordList.css'; // Or wherever your modal styling resides

function ShareLibraryModal({ show, onClose, onConfirm }) {
  const [description, setDescription] = useState('');
  const [password, setPassword] = useState('');

  if (!show) return null; // If not visible, render nothing

  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass data back to parent
    onConfirm({ description, password });
  };

  return (
    <div className="modal">
      {/* 
        A smaller, more compact card. 
        Removed "Share Library" heading, 
        just a close (X) in top-right
      */}
      <div className="modal-content" style={{ maxWidth: '95%', width: '320px' }}>
        {/* Minimal header => Just an 'X' button for close */}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button onClick={onClose} style={{ fontSize: '1.2rem', margin: '4px 4px' }}>
            ✕
          </button>
        </div>

        <form onSubmit={handleSubmit} style={{ padding: '0 20px 20px' }}>
          <div className="form-group">
            <label htmlFor="description" style={{ display: 'block', marginTop: '8px' }}>
              Description (optional)
            </label>
            <input
              type="text"
              id="description"
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="E.g. My Tools"
              style={{ marginTop: '4px' }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password" style={{ display: 'block', marginTop: '12px' }}>
              Password (optional)
            </label>
            <input
              type="text"
              id="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="E.g. 'secret123' or leave blank"
              style={{ marginTop: '4px' }}
            />
          </div>

          <div style={{ marginTop: '20px', textAlign: 'right' }}>
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ShareLibraryModal;
