// ItemCard.js
import React from 'react';
import './RecordList.css'; // or wherever .item-card is defined

function ItemCard({ item, isSelected = false, onItemSelect }) {
  const imageUrl = `/images/${item.thumbnail_url}`;

  // Render the item card with the same structure as before
  // Just add position: relative so we can overlay a highlight
  return (
    <div className="item-card" style={{ position: 'relative' }} onClick={onItemSelect}>
      {/* If selected, overlay a tinted highlight + check icon */}
      {isSelected && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 123, 255, 0.15)', // Subtle tint
            pointerEvents: 'none',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '8px',
              left: '8px',
              fontSize: '1.2rem',
              color: '#007BFF',
            }}
          >
            ✓
          </div>
        </div>
      )}

      <div className="image-container">
        <img src={imageUrl} alt={item.name} />
      </div>
      <div className="card-body">
        <p>{item.name}</p>
      </div>
    </div>
  );
}

export default ItemCard;
