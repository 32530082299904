// SharedLibrary.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import PublicHeader from './PublicHeader'; 
import ItemCard from './ItemCard';
import WishListRow from './WishListRow';
import ItemDetailsModal from './ItemDetailsModal';

// If you have isLoggedIn in your AuthContext
import { useAuth } from './AuthContext'; 

// Import the About page styling (for the toggled section)
import './About.css';

// FontAwesome and your icons for aboutSections
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faListAlt } from '@fortawesome/free-solid-svg-icons';

// The aboutSections data from your original About page
const aboutSections = [
  {
    icon: faHandsHelping,
    title: 'Borrow & Share',
    content: 'Borrow items you need from your community and share the ones you don’t use often. Together, we reduce waste and give everyday items a longer life.'
  },
  {
    icon: faListAlt,
    title: 'Wish List',
    content: 'Looking for something specific? Add it to your Wish List and let others in the community help fulfill your needs while promoting reuse and sustainability.'
  }
];

/** Minimal embedded About Section. We keep it toggled so as not to overwhelm. */
function AboutSection() {
  return (
    <div className="about-page" style={{ padding: '20px', marginBottom: '40px' }}>
      <div className="about-heading-card" style={{ marginBottom: '20px' }}>
        <h1 className="about-page-heading">Welcome to CommunityCycle!</h1>
        <p className="about-intro-text">
          We’re a platform where neighbors share and borrow items to reduce waste and foster community. 
          Why buy something you’ll rarely use? Borrow it from a neighbor, or share items you have sitting around. 
          Together, we minimize the demand for new production and help the planet—one shared item at a time.
        </p>
      </div>
      <div className="about-sections">
        {aboutSections.map((section, index) => (
          <div key={index} className="about-section-card">
            <FontAwesomeIcon icon={section.icon} className="section-icon" />
            <h2 className="section-title">{section.title}</h2>
            <p className="section-content">{section.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function SharedLibrary() {
  const { sharedId } = useParams();

  // If you want to hide "Express Interest" for all users, just set canExpressInterest={false} later
  const { isLoggedIn } = useAuth(); 

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [promptPassword, setPromptPassword] = useState(false);

  // Library info
  const [owner, setOwner] = useState(null);
  const [sharedLibrary, setSharedLibrary] = useState(null);
  const [items, setItems] = useState([]);

  // Password prompt
  const [passwordInput, setPasswordInput] = useState('');

  // For item detail
  const [selectedItem, setSelectedItem] = useState(null);

  // Show/hide about 
  const [showAbout, setShowAbout] = useState(false);
  const aboutRef = useRef(null);

  useEffect(() => {
    if (sharedId) {
      fetchSharedLibrary();
    }
  }, [sharedId]);

  const fetchSharedLibrary = async (providedPassword = '') => {
    try {
      setLoading(true);
      setError('');
      setPromptPassword(false);

      const headers = {};
      if (providedPassword.trim()) {
        headers['x-shared-library-password'] = providedPassword.trim();
      }

      const response = await axios.get(`/api/shared-library/${sharedId}`, { headers });
      setOwner(response.data.owner);
      setSharedLibrary(response.data.sharedLibrary);
      setItems(response.data.items);
    } catch (err) {
      if (!err.response) {
        setError('Network error or server unreachable.');
      } else {
        const { status, data } = err.response;
        if (status === 401) {
          setError(data.error || 'Password required.');
          setPromptPassword(true);
        } else if (status === 404) {
          setError('Shared library not found.');
        } else if (status === 410) {
          setError('This shared library link has expired.');
        } else {
          setError(data.error || 'An unknown error occurred.');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    fetchSharedLibrary(passwordInput);
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item);
  };
  const handleCloseDetail = () => {
    setSelectedItem(null);
  };

  // E.g. "Priyanka's Shared Library" 
  const getOwnerHeading = () => {
    if (!owner || !owner.name) return "(Unknown)'s Shared Library";
    const firstName = owner.name.trim().split(/\s+/)[0] || owner.name;
    return `${firstName}'s Shared Library`;
  };

  const handleShowAbout = () => {
    setShowAbout(prev => !prev);
    if (!showAbout) {
      setTimeout(() => {
        aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };

  return (
    <div style={{ backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      {/* Reuse your minimal public header */}
      <PublicHeader />

      <div style={{
        maxWidth: '600px',
        margin: '0 auto',
        padding: '0 15px',
        fontFamily: 'sans-serif'
      }}>
        {/* Loading State */}
        {loading && (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <p>Loading...</p>
          </div>
        )}

        {/* Error State */}
        {!loading && error && (
          <div style={{
            color: 'red',
            marginTop: '20px',
            marginBottom: '1rem',
            textAlign: 'center',
            fontSize: '0.95rem'
          }}>
            {error}
          </div>
        )}

        {/* Password Prompt */}
        {promptPassword && !loading && (
          <div style={{
            backgroundColor: '#fff',
            margin: '20px auto',
            padding: '15px 20px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            maxWidth: '340px'
          }}>
            <p style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '10px' }}>
              This library is password-protected.
            </p>
            <form onSubmit={handlePasswordSubmit} style={{ textAlign: 'center' }}>
              <label
                htmlFor="passwordInput"
                style={{ display: 'block', marginBottom: '6px', fontSize: '0.9rem' }}
              >
                Enter Password:
              </label>
              <input
                id="passwordInput"
                type="password"
                value={passwordInput}
                onChange={(e) => setPasswordInput(e.target.value)}
                style={{
                  width: '100%',
                  padding: '8px',
                  marginBottom: '12px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
              <button
                type="submit"
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '0.9rem'
                }}
              >
                Submit
              </button>
            </form>
          </div>
        )}

        {/* Main library content if no error and loaded */}
        {!loading && !error && sharedLibrary && (
          <div style={{ marginTop: '15px' }}>
            <h3 style={{
              fontSize: '1.15rem',
              fontWeight: 'bold',
              marginBottom: '8px',
              textAlign: 'center'
            }}>
              {getOwnerHeading()}
            </h3>

            {sharedLibrary.description && (
              <p style={{
                fontSize: '0.95rem',
                textAlign: 'center',
                color: '#555',
                margin: '5px 0 15px'
              }}>
                {sharedLibrary.description}
              </p>
            )}

            {items.length === 0 ? (
              <div style={{
                textAlign: 'center',
                color: '#666',
                margin: '20px 0'
              }}>
                No items found in this library.
              </div>
            ) : (
              <div className="row mt-3">
                <div className="d-flex flex-wrap justify-content-around">
                  {items.map((item) => {
                    // If library can contain wishlist items, check item.isWishlist
                    if (item.isWishlist) {
                      return (
                        <WishListRow
                          key={item.id}
                          wishlist={item}
                          onItemSelect={() => handleItemSelect(item)}
                        />
                      );
                    }

                    return (
                      <ItemCard
                        key={item.id}
                        item={item}
                        onItemSelect={() => handleItemSelect(item)}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {/* CTA for curiosity about registering */}
            <div style={{
              marginTop: '20px',
              fontSize: '0.85rem',
              color: '#666',
              textAlign: 'center'
            }}>
              Want to create your own shared library?
              <br />
              <a
                href="https://communitycycle.app/register"
                style={{ color: '#007bff', textDecoration: 'none', fontWeight: '500' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Register now!
              </a>
            </div>

            {/* Toggler for "About" content */}
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <button
                onClick={handleShowAbout}
                style={{
                  padding: '8px 20px',
                  borderRadius: '4px',
                  backgroundColor: showAbout ? '#dc3545' : '#007bff',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '0.9rem',
                  marginBottom: '15px'
                }}
              >
                {showAbout ? 'Hide More Info' : 'Learn More About CommunityCycle'}
              </button>
            </div>

            {showAbout && (
              <div ref={aboutRef}>
                <AboutSection />
              </div>
            )}
          </div>
        )}

        {/* If user taps an item => show detail. canExpressInterest set to false. */}
        {selectedItem && (
          <ItemDetailsModal
            item={selectedItem}
            onClose={() => setSelectedItem(null)}
            canExpressInterest={false} 
          />
        )}
      </div>
    </div>
  );
}

export default SharedLibrary;
