// NotificationContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useUser } from './UserContext';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { userId } = useUser();
  const [notifications, setNotifications] = useState({
    approve_interests: 0,
    approved_new_requests: 0,
    new_matching_wishlist: 0,
    pending_requests: 0 // New field for pending connection requests
  });

  const fetchNotifications = async () => {
    try {
        if (userId) {
            const response = await axios.get(`/api/notifications?userId=${userId}`);
            setNotifications({
                approve_interests: response.data.approveInterests || 0,
                approved_new_requests: response.data.approvedNewRequests || 0,
                new_matching_wishlist: response.data.newMatchingWishlist || 0,
                pending_requests: response.data.pendingRequests || 0 // Ensure your API returns this
            });
        }
    } catch (error) {
        console.error('Failed to fetch notifications:', error);
    }
};


  useEffect(() => {
    fetchNotifications();
    // Optionally, set up polling or WebSocket for real-time updates
  }, [userId]);

  return (
    <NotificationContext.Provider value={{ notifications, fetchNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
