import React, { useState, useRef, useEffect } from 'react';
import { useUser } from './UserContext';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';

import { API_URL } from './constants';
import './AddItemModal.css'; // Assuming you have a CSS file for styling

function AddItemForm({ onClose, giveaway_flag }) {
    const [currentStep, setCurrentStep] = useState(1);
    const [itemName, setItemName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [error, setError] = useState('');
    const [audioBlob, setAudioBlob] = useState(null);
    const [editItemName, setEditItemName] = useState(false); // To toggle editing for item name
    const [editDescription, setEditDescription] = useState(false); // To toggle editing for description
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);

    const [useSpeech, setUseSpeech] = useState(true); // To toggle between manual and speech-to-text
    const [groups, setGroups] = useState([]);
    const [connections, setConnections] = useState([]);


    const [selectedGroups, setSelectedGroups] = useState(new Set()); // Initialize as a Set
    const [selectedConnections, setSelectedConnections] = useState(new Set()); // Initialize as a Set
    const [activeSection, setActiveSection] = useState(null); // null, 'groups', or 'individuals'

    const [transcribing, setTranscribing] = useState(false); // New state for tracking transcription status
    const [isSubmitting, setIsSubmitting] = useState(false);


    const [loading, setLoading] = useState(true);

    const mediaRecorderRef = useRef(null);

    const { userId } = useUser();

    const selectAllRef = useRef(null);

    // Fetch Groups and Connections on Mount
    useEffect(() => {
        fetchGroupsAndConnections();
    }, []);

    const fetchGroupsAndConnections = async () => {
        try {
            const groupsResponse = await fetch(`${API_URL}/user/${userId}/groups`);
            const groupsData = await groupsResponse.json();
            setGroups(groupsData);

            const connectionsResponse = await fetch(`${API_URL}/user/${userId}/connections`);
            const connectionsData = await connectionsResponse.json();
            setConnections(connectionsData);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching groups and connections:', error);
            setError('Error fetching groups and connections');
            setLoading(false);
        }
    };
    
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        } else {
            setImage(null);
            setImagePreview('');
        }
    };


    const toggleListening = (e) => {
        e.stopPropagation();  // Add this line

        if (isListening) {
            mediaRecorderRef.current.stop();
            setIsListening(false);
            mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());

        } else {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    let options = { mimeType: 'audio/wav' };
                    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                        console.error(`${options.mimeType} is not supported, switching to default`);
                        options = {};  // Fallback to the default MIME type.
                    }
                    mediaRecorderRef.current = new MediaRecorder(stream, options);
                    mediaRecorderRef.current.start(); // Starting recording with the media recorder reference

                    const audioChunks = [];
                    mediaRecorderRef.current.ondataavailable = event => {
                        audioChunks.push(event.data);
                    };

                    mediaRecorderRef.current.onstop = () => {
                        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                        setAudioBlob(audioBlob);
                        console.log('Audio blob created:', audioBlob);
                        uploadAudioFile(audioBlob);  // Invoke uploadAudioFile here
                    };

                    setIsListening(true);
                })
                .catch(err => {
                    console.error('Failed to access microphone:', err);
                    setError('Failed to access microphone.');
                });

        }
    };

    const uploadAudioFile = async (audioBlob) => {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'audio.wav');

        try {
            console.log('Uploading audio file...');
            const response = await fetch(`${API_URL}/upload-audio`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            console.log('Audio file uploaded:', data);
            if (data.filePath) {
                transcribeAudio(data.filePath);  // Invoke transcribeAudio with the file path
            } else {
                setError('Error uploading audio file');
            }
        } catch (err) {
            console.error('Error uploading audio file:', err);
            setError('Error uploading audio file');
        }
    };

    const transcribeAudio = async (filePath) => {
        setTranscribing(true); // Start the spinner before making the request

        try {
            console.log('Requesting transcription for file:', filePath);
            let type = 'item'
            const response = await fetch(`${API_URL}/transcribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ filePath, type }),
            });
            const data = await response.json();
            console.log('Transcription and refinement result:', data);
            setDescription(data.refinedDescription);
            setItemName(data.itemName);
            setTags(data.tags); // Store tags
            setCategories(data.categories); // Store categories
            setTranscribing(false); // Stop the spinner after receiving the response

        } catch (err) {
            console.error('Error transcribing audio:', err);
            setError('Error transcribing audio');
            setTranscribing(false); // Stop the spinner after receiving the response

        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Start the spinner animation

        const formData = new FormData();

        const groupsArray = Array.from(selectedGroups);
        const connectionsArray = Array.from(selectedConnections);

        formData.append('name', itemName);
        formData.append('description', description);
        formData.append('image', image);
        formData.append('groups', JSON.stringify(groupsArray)); // Ensure groups are in array format
        formData.append('connections', JSON.stringify(connectionsArray)); // Ensure connections are in array format


        formData.append('tags', JSON.stringify(tags)); // Ensure tags are included
        formData.append('categories', JSON.stringify(categories)); // Ensure categories are included
        formData.append('userId', userId); // Add userId to the form data
        formData.append('giveaway_flag', giveaway_flag)
        if (audioBlob) {
            formData.append('audio', audioBlob, 'audio.wav');
        }

        try {
            console.log('Submitting form data...');
            const response = await fetch(`${API_URL}/add-item`, {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });
            setIsSubmitting(false); // Stop the spinner animation

            if (response.ok) {
                alert('Submission was successful!'); // Show success message
                onClose();
            } else {
                const errorData = await response.json();
                console.error('Error adding item:', errorData.message);
                setError(errorData.message);
            }
        } catch (error) {
            console.error('Failed to add the item:', error);
            setError('Failed to add the item.');
            setIsSubmitting(false); // Ensure spinner is stopped if there's an error
        }
    };

    const handleCloseClick = (e) => {
        e.stopPropagation();
        onClose();
    };


    const renderEditableText = (field, value, setValue, isEditing, setEditing) => {
        return isEditing ? (
            <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onBlur={() => setEditing(false)}
                autoFocus
                className="text-input"
            />
        ) : (
            <div onClick={() => setEditing(true)}>
                <span>{value}</span>
                <span role="img" aria-label="edit">✏️</span>
            </div>
        );
    };

    const toggleGroupSelection = groupId => {
        setSelectedGroups(prevGroups => {
            const newGroups = new Set(prevGroups); // Clone the previous Set
            if (newGroups.has(groupId)) {
                newGroups.delete(groupId); // If it's already selected, remove it
            } else {
                newGroups.add(groupId); // If it's not selected, add it
            }
            return newGroups;
        });
    };

    const toggleConnectionSelection = connectionId => {
        setSelectedConnections(prevConnections => {
            const newConnections = new Set(prevConnections); // Clone the previous Set
            if (newConnections.has(connectionId)) {
                newConnections.delete(connectionId);
            } else {
                newConnections.add(connectionId);
            }
            return newConnections;
        });
    };

    const toggleActiveSection = section => {
        if (activeSection === section) {
            setActiveSection(null);  // If the current section is clicked again, collapse it
        } else {
            setActiveSection(section);  // Set the new active section and automatically close the other
        }
    };

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            // Select all groups and connections
            const allGroupIds = groups.map(group => group.id);
            const allConnectionIds = connections.map(connection => connection.id);
            setSelectedGroups(new Set(allGroupIds));
            setSelectedConnections(new Set(allConnectionIds));
        } else {
            // Deselect all
            setSelectedGroups(new Set());
            setSelectedConnections(new Set());
        }
    };

    const actionType = giveaway_flag ? 'Offer' : 'Lend';

    return (
        <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-header">
                    <button onClick={handleCloseClick} className="close-btn">&#10005;</button>
                </div>
               

                {currentStep === 1 && (
                <div className="form-step">
                    {/* Instruction Message: Only show if no image is uploaded */}
                    {!imagePreview && (
                        <div className="instruction-message">
                            <strong>{actionType} Item</strong>
                            <p>Please follow the steps below to {actionType.toLowerCase()} your item.</p>
                        </div>
                    )}

                    {/* Image Upload Section */}
                    <input
                        type="file"
                        id="image-upload"
                        onChange={handleImageChange}
                        className="file-input-hidden"
                        accept="image/*"
                    />
                    <label htmlFor="image-upload" className="file-input-label">
                        Add Photo
                    </label>
                    {imagePreview && (
                        <div className="image-preview-container">
                            <img src={imagePreview} alt="Preview" className="image-preview" />
                            {/* Optionally, add a remove button */}
                            <button
                                onClick={() => setImagePreview(null)}
                                className="btn btn-secondary remove-btn"
                            >
                                Remove Photo
                            </button>
                        </div>
                    )}

                    {/* Next Button */}
                    <button
                        onClick={() => setCurrentStep(2)}
                        className="btn btn-primary next-btn"
                        disabled={!imagePreview} // Disable until image is uploaded
                    >
                        <FontAwesomeIcon icon={faArrowRight} /> {/* Next */}
                    </button>
                </div>
            )}

                {currentStep === 2 && (
                    <div className="form-step">
                        <div className="switch-label">
                            <label for="toggle-switch">Use voice for description:</label>
                            <label className="switch">
                                <input type="checkbox" id="toggle-switch" checked={useSpeech} onChange={() => setUseSpeech(!useSpeech)} />
                                <span className="slider round"></span>
                            </label>
                        </div>



                        {useSpeech ? (
                            <>
                                <button onClick={toggleListening} className="voice-btn">
                                    {isListening ? 'Stop Recording' : 'Start Recording'}
                                </button>
                                {transcribing && (
                                    <div className="siri-spinner">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                )}
                                {(itemName || description) && (
                                    <div className="details-container">
                                        <div className="detail-item">
                                            <label htmlFor="itemName" className="detail-label">Name:</label>
                                            {renderEditableText('itemName', itemName, setItemName, editItemName, setEditItemName)}
                                        </div>
                                        <div className="detail-item">
                                            <label htmlFor="description" className="detail-label">Description:</label>
                                            {renderEditableText('description', description, setDescription, editDescription, setEditDescription)}
                                        </div>
                                    </div>
                                )}

                            </>
                        ) : (
                            <>
                                <div className="details-container">
                                    <div className="detail-item">
                                        <label htmlFor="itemName" className="detail-label">Item Name:</label>
                                        <input
                                            id="itemName"
                                            type="text"
                                            placeholder="Enter item name"
                                            value={itemName}
                                            onChange={(e) => setItemName(e.target.value)}
                                            className="text-input"
                                        />
                                    </div>
                                    <div className="detail-item">
                                        <label htmlFor="description" className="detail-label">Description:</label>
                                        <textarea
                                            id="description"
                                            placeholder="Enter description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            className="textarea-input"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="button-container">
                            {itemName || description ? (
                                <>
                                    <button onClick={() => setCurrentStep(currentStep - 1)} className="btn btn-secondary half-width">
                                        <FontAwesomeIcon icon={faArrowLeft} /> {/* Back */}
                                    </button>
                                    <button onClick={() => setCurrentStep(3)} className="btn btn-primary half-width">
                                        <FontAwesomeIcon icon={faArrowRight} /> {/* Next */}
                                    </button>
                                </>
                            ) : (
                                <button onClick={() => setCurrentStep(currentStep - 1)} className="btn btn-secondary full-width">
                                    <FontAwesomeIcon icon={faArrowLeft} /> {/* Back */}
                                </button>
                            )}
                        </div>


                    </div>

                )}

{currentStep === 3 && (
                    <div className="form-step">
                        <h3>Select Recipients</h3>

                        {/* Instruction Message: Show only when no selections are made */}
                        {selectedGroups.size === 0 && selectedConnections.size === 0 && (
                            <div className="instruction-message">
                                <strong>Select Recipients</strong>
                                <p>You can quickly select your entire network or customize your selection by removing specific entities.</p>
                            </div>
                        )}

                        {/* "Select All Network" Checkbox */}
                        <div className="select-all-container">
                            <input
                                type="checkbox"
                                id="select-all"
                                ref={selectAllRef}
                                onChange={handleSelectAllChange}
                            />
                            <label htmlFor="select-all">Select All Network</label>
                        </div>

                        {/* Toggle between Groups and Individuals */}
                        <div className="recipient-toggle">
                            <h4
                                className="toggle-header"
                                onClick={() => toggleActiveSection('groups')}
                            >
                                Groups
                            </h4>
                            <h4
                                className="toggle-header"
                                onClick={() => toggleActiveSection('individuals')}
                            >
                                Individuals
                            </h4>
                        </div>

                        <div className="recipient-details">
                            {!isSubmitting && activeSection === 'groups' && (
                                <div className="detail-item">
                                    {groups.map(group => (
                                        <div
                                            key={group.id}
                                            className={`card ${selectedGroups.has(group.id) ? 'selected' : ''}`}
                                            onClick={() => toggleGroupSelection(group.id)}
                                        >
                                            {group.group_name}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {!isSubmitting && activeSection === 'individuals' && (
                                <div className="detail-item">
                                    {connections.map(connection => (
                                        <div
                                            key={connection.id}
                                            className={`card ${selectedConnections.has(connection.id) ? 'selected' : ''}`}
                                            onClick={() => toggleConnectionSelection(connection.id)}
                                        >
                                            {connection.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Navigation Buttons */}
                        <div className="button-container">
                            <button onClick={() => setCurrentStep(currentStep - 1)} className="btn btn-secondary">
                                <FontAwesomeIcon icon={faArrowLeft} /> {/* Back */}
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="btn btn-primary"
                                disabled={selectedGroups.size === 0 && selectedConnections.size === 0} // Disable if no recipients selected
                                aria-disabled={selectedGroups.size === 0 && selectedConnections.size === 0}
                            >
                                <FontAwesomeIcon icon={faCheck} /> {/* Submit */}
                            </button>
                        </div>
                    </div>
                )}

            </div>
        </div >
    );
}

export default AddItemForm;
