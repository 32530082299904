// ShareLinkModal.js
import React from 'react';
import './RecordList.css'; // or your modal styles

function ShareLinkModal({ show, link, onClose }) {
  if (!show) return null;

  return (
    <div className="modal">
      <div className="modal-content" style={{ maxWidth: '95%', width: '320px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button onClick={onClose} style={{ fontSize: '1.2rem', margin: '4px 4px' }}>
            ✕
          </button>
        </div>

        <div style={{ padding: '0 20px 20px' }}>
          <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>
            Your Share Link:
          </p>
          <div
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              wordWrap: 'break-word',
              backgroundColor: '#f9f9f9'
            }}
          >
            {link}
          </div>
          <p style={{ marginTop: '8px', fontSize: '0.9rem', color: '#666' }}>
            Long-press on the link to copy manually.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ShareLinkModal;
